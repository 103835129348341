<template>
<v-app>
    <router-view />
  <notify ref="notify"/>
</v-app>
</template>
<script>
import notify from '@/components/notify'
import { getSoftwareHelp  } from "@/api/help.js";
import ApiIntegrface from './api/api';
import {jwtDecode} from 'jwt-decode';

export default {
  components: {notify},
   mounted() {
      this.$root.notify = this.$refs.notify
      this.$root.ctrlKey = false
      this.$root.startRefreshTokenTimer = this.startRefreshTokenTimer
      this.$root.refreshTimer = null
      this.$root.logout = this.logout
      const _this = this
      // console.log('APP')
      getSoftwareHelp((res)=>{ 
        let r = {}
        res.forEach(element => {
          r[`${element.section}__${element.name}`] = element.description
        });
        _this.$root.help = r
        _this.$root.rawHelp=res
      })
      window.onkeyup = this.onKeyEvent
      window.onkeydown = this.onKeyEvent
      if(this.$store.state.Login && this.$root.refreshTimer == null){
          const api = new ApiIntegrface()
          const _this = this
          api.doRefreshToken(()=>{
            _this.startRefreshTokenTimer()
          },
        )
      }
    },
    methods:{
      onKeyEvent(e){
        this.$root.ctrlKey = e.ctrlKey
        if(e.key == 'Control'){
          this.$eventBus.$emit('onCtrl'+e.type)
        }
      },
      startRefreshTokenTimer(){
        clearTimeout(this.$root.refreshTimer)
        const token = jwtDecode(this.$store.state.Token)
        const _this = this
        this.$root.refreshTimer = setTimeout(()=>{
          const api = new ApiIntegrface()
          api.doRefreshToken(()=>{
            _this.$root.startRefreshTokenTimer()
          },
        )
        }, ( token.exp - Date.now()/1000 - 10 )*1000 )
      },
      logout(){
        clearTimeout(this.$root.refreshTimer)
        this.$store.commit('logout')
        this.$router.push('/login')
      }
    }
}
</script>


<style scoped>
 ::v-deep .v-tooltip__content{
  border: 1px solid rgb(3, 147, 168,0.2) !important;
  /* border-color:rgb(3, 147, 168,0.2); */
  border-radius: 6px;
  backdrop-filter: blur(4px) !important;
  box-shadow: 0px 4px 5px 3px rgba(217, 217, 217, 0.6);
  font-family: 'IBM Plex sans';
  font-size: 12px !important;
  /* color:var(--v-primary-base) !important; */
  background-color: white;
  color: black;
  opacity: .9;
}

 .theme--dark ::v-deep .v-tooltip__content{
  box-shadow: none;
  background-color: black !important;
  opacity: .8;
  color: white !important;
}


/* width */
::v-deep ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::v-deep ::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::v-deep ::-webkit-scrollbar-thumb {
  background: #2bacc080; 
}

/* Handle on hover */
::v-deep ::-webkit-scrollbar-thumb:hover {
  background: #0393A890; 
}

</style>
